<template>
  <div class="w-100">
    <div class="mb-3" v-if="loading">Chargement...</div>
    <div class="d-flex justify-content-between align-items-center mb-3" v-if="!loading">
      <div>
        <h5 class="m-0">
          Liste des visites <span v-if="!old">{{ $moment().format("YYYY") }}</span>
        </h5>
        <div class="text-primary cursor-pointer" @click="getOldVisites" v-if="!old">
          Afficher les visites plus anciennes
        </div>
        <div class="text-primary cursor-pointer" @click="getVisites" v-if="old">Afficher les visites récentes</div>
      </div>
      <btn class="btn btn-primary" @click="createNcs()" :loading="loading" text="Ajouter" icon="plus" />
    </div>
    <!-- <div class="d-flex flex-column flex-lg-row">
      <filtre-select
        v-model="filterPilote"
        inputText="Pilote"
        :options="users"
        field="collaborateur_nom"
        class="mb-3 mr-3"
        :disabled="false"
      ></filtre-select>
    </div>-->
    <div class="mb-3" v-if="!loading">
      <div v-for="visite in visitesNews" :key="'visite_new_' + visite.id" class="w-100">
        <visitesItem :visite="visite"></visitesItem>
      </div>
      <div v-for="visite in visitesOlds" :key="'visite_' + visite.id" class="w-100">
        <visitesItem :visite="visite"></visitesItem>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { mapMultiRowFields } from "vuex-map-fields";
import visitesItem from "@/components/qsser/visitesItem.vue";
import btn from "@/components/bases/Button.vue";
// import FiltreSelect from "@/components/bases/FiltreSelect.vue";
// var fr = require("apexcharts/dist/locales/fr.json");

export default {
  components: {
    visitesItem,
    btn,
    //FiltreSelect
  },
  data() {
    return {
      old: false,
      loading: false,
      filterPilote: null,
      filterOrigine: null,
    };
  },
  mounted() {
    this.getVisites();
    //this.getAffaires();
  },
  methods: {
    ...mapActions({
      _getVisites: "qsser/getVisites",
      _getOldVisites: "qsser/getOldVisites",
      _createVisites: "qsser/createVisites",
      //getAffaires: "affaires/getAffaires",
    }),
    getVisites: function () {
      this.loading = true;
      this._getVisites()
        .then(() => (this.old = false))
        .finally(() => (this.loading = false));
    },
    getOldVisites: function () {
      this.loading = true;
      this._getOldVisites()
        .then(() => (this.old = true))
        .finally(() => (this.loading = false));
    },
    createVisites: function () {
      this.loading = true;
      this._createVisites().finally(() => (this.loading = false));
    },
  },
  computed: {
    ...mapGetters({
      data: "qsser/data",
      users: "user/users",
    }),
    ...mapMultiRowFields("qsser", ["visites", "actions"]),
    //...mapMultiRowFields("affaires", ["affaires"]),
    //...mapMultiRowFields("user", ["users"]),
    /* affairesList() {
      if (!this.affaires) return [];
      return this.affaires.map((affaire) => {
        return {
          id: affaire.id,
          description: affaire.affaire_identifiant + " - " + affaire.affaire_libelle,
        };
      });
    },*/
    visitesNews() {
      return this.visites.filter((visite) => visite.visite_new == true).reverse();
    },
    visitesOlds() {
      let rep = [];
      rep = this.visites.filter((visite) => visite.visite_new != true).reverse();
      // if (this.filterPilote) rep = rep.filter((visite) => visite.visite_user_id == this.filterPilote);
      // if (this.filterOrigine) rep = rep.filter((nc) => nc.nc_origine_id == this.filterOrigine);

      return rep;
    },

    qwarkBorder() {
      if (!this.$store.getters["colors/darkMode"]) {
        return "qwark-border-light";
      } else {
        return "qwark-border-dark";
      }
    },
  },
};
</script>
<style></style>
